import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import LockOpenIcon from "@mui/icons-material/LockOpenOutlined";
import LockIcon from "@mui/icons-material/Lock";

import { useLocation, useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const UpdatePasswordUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Update Password",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        if (newPassword === confirmPassword) {
          const result = await apiClient.put("/users/profile", {
            id: data._id,
            password: newPassword,
          });
          if (result.ok) {
            setNewPassword("");
            setConfirmPassword("");
            swal("Password Updated", "Successfully", "success");
            navigate("/customer/list");
          } else {
            swal("Error!", "Occured Retry", "error");
          }
        } else {
          swal("Error!", "Password mismatch retry", "error");
        }
      }
    });
  };

  

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Change Password for Customer</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Update Password for {data?.name}</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <div  className="loginPassword mb-3">
                          <LockOpenIcon />
                          <input
                            type="password"
                            placeholder="New Password"
                            required
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                        <div className="loginPassword">
                          <LockIcon />
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpdatePasswordUser;
