import React, { useState, useEffect } from "react";
import {
  Button,
  MenuItem,
  Select,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton,
} from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { useLocation, useNavigate } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "./AddService.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const UpdateService = () => {
  const loc = useLocation();

  const intialState = {
    jobs: loc.state.details,
  };
  const [file2, setFile2] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState(loc.state.category._id);
  const [vendor, setVendor] = useState();
  const [vendorId, setVendorId] = useState(loc.state.vendor._id);
  const [hotelImages, sethotelImages] = useState(loc?.state?.image);
  const [file3, setFile3] = useState([]);
  const [jobsData, setJobsData] = useState(intialState);

  const navigate = useNavigate();
  const classes = useStyles();
  
  const [state, setState] = useState({
    name: loc.state.name,
    city: loc.state.city,
    image: loc.state.image,
    description: loc.state.description,
    size: loc.state.size,
    color: loc.state.color,
    brand: loc.state.brand,
    details: [],

    sell_price: loc.state.sell_price,
    discount: loc.state.discount,

    notes: "",
  });

  useEffect(() => {
    // fetchCategories();
    fetchVendors();
  }, []);

  const fetchVendors = async () => {
    const { data } = await apiClient.get("/vendor");

    setVendor(data);
  };

  //   const fetchBrands = async () => {
  //     const { data } = await apiClient.get("/variations/getbrand");
  //     setBrands(data);
  //   };

  //   const fetchSizes = async () => {
  //     const { data } = await apiClient.get("/variations/getsize");
  //     setSizes(data);
  //   };

  // const fetchCategories = async () => {
  //   const { data } = await apiClient.get("/category/all");
  //   setCat(data);
  // };

  const uploadFileHandler = async (e) => {
    setFile3([...file3, ...e.target.files]);
  };

  const handleSubmit2 = async () => {
    if (file3) {
      const formData = new FormData();

      for (const file of file3) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = await apiClient.post(
        "/uploadImages/uploadImages",
        formData,
        config
      );
      if (result.ok) {
        swal("Images Uploaded Successfully");
        setFile3([]);
        sethotelImages([...hotelImages, ...result.data]);
      } else {
        swal("Error Retry!");
      }
    }
  };

  const removeImage = async (value) => {
    const result = await apiClient.delete("/uploadImages/deleteImage", {
      image: value,
    });
    if (result.ok) {
      let newArr = hotelImages.filter((img) => img !== value);
      sethotelImages(newArr);
    }
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // if (file2.length < 1) {
    //   swal("Please select image");
    // } else {
    swal({
      title: "Are you sure?",
      text: "You want to Update Service",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        // if (file2) {
          // const formData = new FormData();

          // for (const file of file2) {
          //   formData.append("image", file, file.name);
          // }

          // const config = {
          //   headers: {
          //     "Content-Type": "multipart/form-data",
          //   },
          // };
          // const { data } = await apiClient.post(
          //   "/uploadImages/uploadImages",
          //   formData,
          //   config
          // );

          // if (data) {
            const result1 = await apiClient.post("/service/update", {
              name: state.name,
              id: loc.state._id,
              image: hotelImages,
              // color: state.color,
              // brand: state.brand,
              category: catId,
              vendor: vendorId,

              sell_price: state.sell_price,
              discount: state.discount,

              notes: state.notes,
              description: state.description,

              details: jobsData.jobs,
            });

            if (result1.ok) {
              setState({
                name: "",

                image: "",
                description: "",
                city: "",
                brand: "",
                color: "",
                details: "",

                sell_price: "",
                discount: "",

                notes: "",
              });
              setFile2([]);
              swal("Success Service added");
              navigate("/service/all");
            // } else {
            //   swal(
            //     "Service Upload failed due to network issue or missing fields"
            //   );
            // }
          // } else {
          //   swal("Service upload Failed. Please Try again!!!!!");
          //   navigate("/service/all");
          // }
        }
      }
    });
    // }
  };

  const handleChange2 = (index, e) => {
    const values = [...jobsData.jobs];
    values[index] = e.target.value;
    setJobsData({ ...jobsData, jobs: values });
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setJobsData((prevState) => ({
      ...prevState,
      jobs: [...prevState.jobs, ""],
    }));
  };

  const handleRemoveField = (index) => {
    const values = jobsData.jobs;
    values.splice(index, 1);
    setJobsData((prevState) => ({ ...prevState, values }));
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Services</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Services</a>
            </li>
            <li className="breadcrumb-item active">Add Service</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Service</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Service Title*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <br />

                    <br />
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Vendors*</label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={vendorId}
                            label="Sub-Category"
                            onChange={(e) => setVendorId(e.target.value)}
                          >
                            {vendor?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Description*</label>
                        <input
                          style={{ overflow: "scroll" }}
                          type="text"
                          className="form-control"
                          placeholder="Product Description"
                          name="description"
                          value={state.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    {/* <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Video URL</label>
                        <input
                          style={{ overflow: "scroll" }}
                          type="text"
                          className="form-control"
                          placeholder="Video URL"
                          name="video"
                          value={state.video}
                          onChange={handleChange}
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <div>
                          <TableContainer
                            component={Paper}
                            className="tb-container"
                          >
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Details</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {jobsData?.jobs?.map((itemField, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="left">
                                      <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        type="text"
                                        name="name"
                                        onChange={(e) =>
                                          handleChange2(index, e)
                                        }
                                        value={itemField}
                                        placeholder="Detail"
                                      />
                                    </TableCell>

                                    <TableCell align="left">
                                      <IconButton
                                        onClick={() => handleRemoveField(index)}
                                      >
                                        <DeleteOutlinedIcon
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div className="addButton">
                            <button onClick={handleAddField}>+</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product S.P"
                            name="sell_price"
                            value={state.sell_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Discount*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Disount"
                            name="discount"
                            value={state.discount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Notes*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          value={state.notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    {/* <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Select images for the above product
                        </label>
                        <form>
                          <input
                            type="file"
                            multiple
                            onChange={uploadFileHandler}
                          />
                        </form>
                      </div>
                    </div> */}
<label className="form-label">Service Images*</label>
                    <div style={{ display: "flex" }}>
                      <form>
                        <input
                          type="file"
                          multiple
                          onChange={uploadFileHandler}
                        />
                      </form>
                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit2}
                      >
                        Update Images
                      </button>
                    </div>

                    {hotelImages?.length > 0 && (
                      <p
                        style={{
                          fontWeight: "500",
                          textDecoration: "underline",
                        }}
                      >
                        Existing Images( {hotelImages?.length})
                      </p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      {hotelImages?.map((item, i) => {
                        return (
                          <div
                            className="prd-img"
                            onClick={() => removeImage(item)}
                          >
                            <img
                              key={i}
                              src={item}
                              alt="product-img"
                              class="update-img"
                            />
                            <div class="middle">
                              <div class="text" style={{ cursor: "pointer" }}>
                                Delete
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Update Service
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpdateService;
