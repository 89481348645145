import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Typography } from "@material-ui/core";
import apiClient from "./../../api/client";
import swal from "sweetalert";

const ListUser = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [getList, setGetList] = useState([]);
  const [del, setDel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, [del]);

  const fetchCategories = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/user");
 
    setGetList(data);
    setIsLoaded(false);
  };
//   const handleCheck = async (row) => {
//     setIsLoaded(true);
  
//     const { data } = await apiClient.get("/rewards/getrewardpoints", {
//       userId: row,
//     });
//     // console.log(data[0]);
 
//     const points = Number(data?.amount).toFixed(2);
//     swal(points)
//   };

const handlDeleteById = async (id) => {
  swal({
    title: "Are you sure?",
    text: "You want to Delete this User",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then(async (success) => {
    if (success) {
     
      const result = await apiClient.delete(`/user/delete`, {id});
      if (result.ok) {
        swal("Delete Success");
        setDel(!del);
      }
    }
  });
};

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Customers</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Customers</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Customers</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>Id</th>

                        <th>Name</th>
                        <th>Email</th>
                        <th >Delete</th>
                        <th>Mobile</th>
                        <th>Notification</th>
                        <th>Password</th>
                      
                        {/* <th>Check Reward</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? getList.map((row, index) => (
                            <tr key={index}>
                              <td>{row._id}</td>

                              <td>{row.name}</td>
                              <td>{row.email}</td>
                              <td className="action-btns">
                                <Typography
                                  className="delete-btn"
                                  onClick={(e) => handlDeleteById(row._id)}
                                >
                                  <i className="fas fa-trash-alt" />
                                </Typography>
                              </td>
                              <td>{row.phone}</td>
                              
                              <td className="action-btns">
                                <Link to="/user/notification" state={row}>
                                  <Typography className="edit-btn">
                                    🔔
                                  </Typography>
                                </Link>
                              </td>
                              <td>
                                <Link to="/users/updatepassword" state={row}>
                                  <Typography className="edit-btn">
                                    <i className="fas fa-edit" />
                                  </Typography>
                                </Link>
                              </td>
                              
                            
                               
                            </tr>
                          ))
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
                {/* <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} /> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ListUser;
