import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

const CreateBanner = () => {
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [photo, setPhoto] = useState();
  const [bg, setBg] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Banner",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const formData = new FormData();

        formData.append("image", photo, photo.name);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await apiClient.post(
          "/uploadSingleImage/uploadSingleImage",
          formData,
          config
        );
      
        const result = await apiClient.post("/banner/create", {
          title: name,
          desc: description,
          img: data,
          bg,
        });
        if (result.ok) {
          setName("");
          setDescription("");
          setPhoto("");
          setBg("");
          swal("Sucess");
          navigate("/banner/list");
        } else {
          swal("Error Retry!");
        }
      }
    });
  };

  const navigate = useNavigate();

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Category</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/banners/image")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Banners</a>
            </li>
            <li className="breadcrumb-item active">Image</li>
          </ol>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Banner</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-4 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Title*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <br />
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Select Image </label>
                        <form>
                          <input type="file" onChange={uploadFileHandler} />
                        </form>
                      </div>
                    </div>
                    <br />
                    {/* <div className="col-lg-6 col-md-2 ">
                      <div className="form-group">
                        <label className="form-label">
                          Category Description*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Category Description"
                          name="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div> */}
                    {/* <br /> */}
                    {/* <div className="col-lg-3 col-md-2 ">
                      <div className="form-group">
                        <label className="form-label">
                          Background Color (*hexcode)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Background Color"
                          name="bg"
                          value={bg}
                          onChange={(e) => setBg(e.target.value)}
                        />
                      </div>
                    </div> */}

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreateBanner;
