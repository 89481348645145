import React from "react";
import { Link } from "react-router-dom";
const SideNavBar = () => {
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseJobCard"
              aria-expanded="false"
              aria-controls="collapseJobCard"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-credit-card" />
              </div>
              Services
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseJobCard"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/service/all">
                  All Services
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/service/create">
                  Add Service
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/service/featured">
                  Featured Services
                </Link>
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseProducts"
              aria-expanded="false"
              aria-controls="collapseProducts"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
             Vendors
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseProducts"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/vendors/all">
                  All Vendors
                </Link>
             
                <Link className="nav-link sub_nav_link" to="/vendor/approve">
                  Approve Vendors
                </Link>
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseCategories"
              aria-expanded="false"
              aria-controls="collapseCategories"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Customer's
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseCategories"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/users/list">
                  All Customers
                </Link>
               
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseOrders"
              aria-expanded="false"
              aria-controls="collapseOrders"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-credit-card" />
              </div>
             Category
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseOrders"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/category/list">
                  All categories
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/category/create">
                  Add Category
                </Link>
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseVarList"
              aria-expanded="false"
              aria-controls="collapseVarList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Booking
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseVarList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/booking/list">
                  All Bookings
                </Link>
                
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapsebanList"
              aria-expanded="false"
              aria-controls="collapseVarList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Banners
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapsebanList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/banner/create">
                  Create Banner
                </Link>
                <Link className="nav-link sub_nav_link" to="/banner/list">
                 List Banner
                </Link>
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              data-toggle="collapse"
              data-target="#collapseQuotationList"
              aria-expanded="false"
              aria-controls="collapseQuotationList"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-credit-card" />
              </div>
              Revenue's
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseQuotationList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/revenue/list">
                  All Revenues
                </Link>
             
              </nav>
            </div>
            
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SideNavBar;
