import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button, Typography } from "@material-ui/core";
import apiClient from "./../../api/client";
import swal from "sweetalert";
import { Pagination } from "@mui/material";

const VendorWiseBookings = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [getListBooking, setGetListBooking] = useState([]);
  const [getListServices, setGetListServices] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageCount, setPageCount] = useState([]);
  const navigate = useNavigate();
  const location = useLocation()
  const vendorId = location.state

  useEffect(() => {
    fetchBookingsByVendors();
  
  }, [currentPageNo]);

  
  const fetchBookingsByVendors = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/booking/by-vendor", {
      userId: vendorId
    });
    console.log(data)
    setGetListBooking(data.orders);
    setPageCount(data.pageCount)
    setIsLoaded(false);
  };



  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Vendor Details</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Details</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Bookings of </h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>#Booking Id</th>
                        <th>#Booking Date</th>
                        <th>#Booking Time</th>
                        <th>Status</th>
                        

                        <th>Customer Name</th>

                        <th>Info</th>
                        <th>Update</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? getListBooking?.map((row, index) => {
                            const date = new Date(row.updatedAt).toDateString();
                            const time = new Date(
                              row.updatedAt
                            ).toLocaleTimeString();

                            const status = row.deliveryStatus;

                            return (
                              <>
                                <tr key={index}>
                                  <td>{row._id}</td>
                                  <td>{date}</td>
                                  <td>{time}</td>
                                  {status === "Processing" && (
                                    <td
                                      style={{
                                        backgroundColor: "yellow",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Completed" && (
                                    <td
                                      style={{
                                        backgroundColor: "#50C878",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Accepted" && (
                                    <td
                                      style={{
                                        backgroundColor: "#50C878",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Cancelled" && (
                                    <td
                                      style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                 
                                

                                  <td>{row?.user?.name}</td>
                                  

                                  <td>
                                    <Link to="/booking/detail" state={row}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link>
                                  </td>
                                  <td className="action-btns">
                                    <Link to="/booking/update" state={row}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link>
                                  </td>
                                 
                                </tr>
                              </>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
          </div>

        </div>
      </main>
    </div>
  );
};

export default VendorWiseBookings;
