import React, { useEffect, useState } from "react";
import { toCommas } from "./utils";
import styles from "./Dashboard.module.css";
import { Check, Pie, Bag, Card, Clock, Frown } from "./Icons";
import apiClient from "../api/client";
import DatePicker from "react-date-picker";
import { MenuItem, Pagination, Select } from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const Revenue = () => {
  const [weeklySales, setWeeklySales] = useState([]);

  const [value1, onChange1] = useState(new Date());
  const [value2, onChange2] = useState(new Date());
  const [modal, setModal] = useState();
  const [vendors, setVendors] = useState();
  const [vendorId, setVendorId] = useState();

  useEffect(() => {
    fetchWeeklyOrders();
    fetchallvendors();
  }, [modal, vendorId]);

  const fetchWeeklyOrders = async () => {
    const { data } = await apiClient.get("/booking/getsalesdaterange", {
      startDate: value1,
      endDate: value2,
    });

    setWeeklySales(data?.monthlySales);
    console.log(data?.monthlySales)
  };
  const fetchallvendors = async () => {
    const { data } = await apiClient.get("/vendor/");

    setVendors(data);
  };
  var total = 0;
  weeklySales?.map((order) => {
    order.orderItems.map((item) => {
      total = total + item?.price * item?.qty;
    });
  });

  var totalOrderPending = 0;
  weeklySales?.map((order) => {
    if (
      order.deliveryStatus !== "Delivered" ||
      order.deliveryStatus !== "Cancelled"
    ) {
    }
  });

  var totalProfit = 0;

  return (
    <div id="layoutSidenav_content">
      <section className={styles.stat}>
        <ul className={styles.autoGrid}>
          <li
            className={styles.listItem}
            style={{ backgroundColor: "#1976d2", color: "white" }}
          >
            <div>
              <p>{toCommas(total.toFixed(0))} QAR</p>
              <h2 style={{ color: "white" }}> Sales</h2>
            </div>
            <div>
              <Check />
            </div>
          </li>

          {/* <div>
            <div className="form-group">
              <label className="form-label">Service Vendor</label>
              <Select
                className="form-control"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={vendorId}
                label="Vendor"
                onChange={(e) => setVendorId(e.target.value)}
              >
                {vendors?.map((item, index) => {
                  return <MenuItem value={item._id}>{item.name}</MenuItem>;
                })}
              </Select>
            </div>
          </div> */}
        </ul>
      </section>

      <section
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "50%",
          margin: "0 auto",
          alignItems: "center",
        }}
      >
        <div>
          <div className="start-div">
            <p>Start Date</p>
            <DatePicker value={value1} onChange={onChange1} />
          </div>
        </div>
        <div>
          <div>
            <p>End Date</p>
            <DatePicker value={value2} onChange={onChange2} />
          </div>
        </div>
        <button
          style={{
            padding: "10px",
            border: "none",
            backgroundColor: "rgb(25, 118, 210)",
            fontWeight: "500",
            borderRadius: "15px",
            color: "white",
            width: "100px",
            height: "40px",
            alignSelf: "end",
            cursor: "pointer",
          }}
          onClick={() => fetchWeeklyOrders()}
        >
          Check
        </button>
      </section>

      <section>
        <h1 style={{ textAlign: "center", padding: "30px" }}>Bookings</h1>
        <div>
          <div className={styles.table}>
            <table>
              <tbody>
                {weeklySales.length !== 0 && (
                  <tr>
                    <th style={{ padding: "15px" }}>#bookingID</th>
                    <th style={{ padding: "15px" }}>Customer Name</th>
                    <th style={{ padding: "15px" }}>Vendor Name</th>
                    <th style={{ padding: "15px" }}>Date</th>
                    <th style={{ padding: "15px" }}>Status</th>
                    <th style={{ padding: "15px" }}>Slot</th>
                    <th style={{ padding: "15px" }}>Total Amount</th>
                  </tr>
                )}

                {weeklySales?.map((record) => (
                  <tr className={styles.tableRow} key={record._id}>
                    <td>{record._id}</td>
                    <td>{record.user?.name}</td>
                    <td>{record.vendor?.name}</td>
                    <td>{new Date(record.updatedAt).toDateString()}</td>
                    {record.deliveryStatus === "Processing" && (
                      <td
                        style={{
                          backgroundColor: "yellow",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    {record.deliveryStatus === "Completed" && (
                      <td
                        style={{
                          backgroundColor: "#50C878",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    {record.deliveryStatus === "Cancelled" && (
                      <td
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}

                    <td>{record?.deliverySlot}</td>
                    <td>{record?.totalPrice} QAR</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Revenue;
